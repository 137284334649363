import React, { useState } from "react";
import Input from "../components/forms/input";
import { ssSignUpSchema } from "./forms/validation/schemas";
import Toastify from "toastify-js";
import "../utils/fonts/fa-solid";
import "../utils/fonts/fontawesome";
import "../styles/toastify.css";

const SoulSurferSignup = () => {
  const initialFormState = {
    username: "",
    email: "",
    "form-name": "ss-signup",
    "bot-field": "",
  };

  const [form, setForm] = useState(initialFormState);

  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const badInput = message =>
    Toastify({
      text: message,
      backgroundColor: "rgba(0,0,0,0.8)",
      gravity: "bottom",
      duration: 3000,
      className: "toastify-error",
    }).showToast();

  const badSend = error =>
    Toastify({
      text: "Oops!  Something went wrong. " + error,
      backgroundColor: "rgba(0,0,0,0.8)",
      gravity: "bottom",
      duration: 5000,
      className: "toastify-error",
    }).showToast();

  const goodSend = () =>
    Toastify({
      text: "Thanks!  I'll keep you posted.",
      backgroundColor: "rgba(0,0,0,0.8)",
      gravity: "bottom",
      duration: 5000,
      className: "toastify-success",
    }).showToast();

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await ssSignUpSchema.validate(form);
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ ...form }),
      });
      goodSend();
      setForm(initialFormState);
    } catch (e) {
      if (!e.name) {
        e => badSend(e.message);
      } else {
        badInput(e.errors);
      }
    }
  };
  return (
    <div
      className="d-flex flex-column align-items-center container mt-4 mt-sm-0"
      style={{ position: "relative" }}
    >
      <h1 className="title--ss mt-5">SOUL SURFER</h1>
      <div className="d-flex flex-column flex-md-row text-light mb-5 mt-3 mt-sm-4">
        <div className="col-sm-7 px-0 px-sm-2">
          <p
            className="text-light p-4 text-justify"
            style={{
              fontFamily: "Sanchez, serif",
              backgroundColor: "rgba(0,0,0,0.6)",
              borderRadius: "0.75rem",
              hyphens: "manual",
            }}
          >
            In the year 2059, an underground hacktivist, JOSEPH PLUMBER,
            develops a “time bomb” technology to send information into the past
            with the hopes to save the world from a dystopian future. When he
            discovers that his time bombs are being used to benefit a select few
            while enslaving the masses, Joe finds himself embroiled in a
            dangerous conspiracy that pervades the highest realms of power.
          </p>
        </div>
        <div className="col-md-5 mt-3 mt-md-0" style={{ minWidth: "300px" }}>
          <p
            className="m-0 text-center"
            style={{
              fontFamily: "'Exo 2', sans-serif",
              textShadow: "2px 2px black",
            }}
          >
            SOUL SURFER is a work in progress.
            <br />
            Sign up here to get notified when it’s released.
          </p>
          <form
            autoComplete="off"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            name="ss-signup"
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <input type="hidden" name="ss-signup" value="ss-signup" />
            <input
              onChange={e => handleChange(e)}
              type="hidden"
              name="bot-field"
              value={form["bot-field"]}
            />
            <Input
              icon="fas fa-user-circle"
              name="username"
              onChange={e => handleChange(e)}
              placeholder="Marnie McCloud"
              type="text"
              value={form.username}
            />
            <Input
              icon="fas fa-envelope"
              name="email"
              onChange={e => handleChange(e)}
              placeholder="mmcloud@burns-lynch.com"
              type="email"
              value={form.email}
            />
            <button className="btn btn btn-outline-light mt-3">
              GET NOTIFIED
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SoulSurferSignup;
