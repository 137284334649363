import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import "../../utils/fonts/fa-solid";
import "../../utils/fonts/fontawesome";

const IconInput = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.35rem;
  font-family: "Exo 2", sans-serif;
  padding: 0.75rem 1rem;
`;

const InputGroup = props => {
  const { icon, name, onChange, placeholder, required = false, value } = props;
  return (
    <IconInput
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginTop: "8px",
      }}
    >
      <i style={{ color: "#8e8e8e" }} className={icon} />
      <input
        css={css`
          flex: 1;
          background: transparent;
          border: none;
          color: white;
          padding-left: 1rem;
          &:focus {
            outline: none;
          }
          &:invalid {
            box-shadow: none;
          }
          &::placeholder {
            font-family: "Exo 2", sans-serif !important;
          }
          @media (min-width: 768px) {
            min-width: 250px;
          }
        `}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        type="text"
        value={value}
      />
    </IconInput>
  );
};

InputGroup.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default InputGroup;
