import { connectSchema } from "./forms/validation/schemas";
import axios from "axios";
import qs from "qs";
import Footer from "./footer";
import Input from "./forms/input";
import React, { useEffect, useRef, useState } from "react";
import Reaptcha from "reaptcha";
import styled from "@emotion/styled";
import Toastify from "toastify-js";
import "../utils/fonts/fa-solid";
import "../utils/fonts/fontawesome";
import "typeface-exo-2";

const TextArea = styled.textarea`
  background-color: rgba(0, 0, 0, 0.6);
  border: none;
  border-radius: 0.35rem;
  color: white;
  font-family: "Exo 2", sans-serif;
  padding: 1rem;
  &:focus {
    outline: none;
  }
  &:invalid {
    box-shadow: none;
  }
`;

const Connect = () => {
  const initialFormState = {
    username: "",
    email: "",
    "form-name": "connect",
    url: "",
    subject: "",
    message: "",
    "bot-field": "",
  };

  const [form, setForm] = useState({ ...initialFormState });
  const [token, setToken] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [verified, setVerified] = useState(false);
  const [executing, setExecuting] = useState(false);

  const rcRef = useRef(null);

  useEffect(() => {
    if (loaded && !verified) {
      rcRef.current.execute();
      setExecuting(true);
    }
  }, [loaded, verified]);

  const handleChange = e => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onLoad = () => {
    setLoaded(true);
  };

  const onVerify = token => {
    setToken(token);
    setVerified(true);
    setExecuting(false);
  };

  const onExpire = () => {
    resetRecaptcha();
  };

  const resetRecaptcha = async () => {
    await rcRef.current.reset;
    setVerified(false);
  };

  const badInput = message =>
    Toastify({
      backgroundColor: "rgba(0,0,0,0.8)",
      className: "toastify-error",
      duration: 3000,
      gravity: "bottom",
      text: message,
    }).showToast();

  const badSend = message =>
    Toastify({
      backgroundColor: "rgba(0,0,0,0.8)",
      className: "toastify-error",
      duration: 5000,
      gravity: "bottom",
      text: "Oops!  Something went wrong. " + message,
    }).showToast();

  const goodSend = () =>
    Toastify({
      backgroundColor: "rgba(0,0,0,0.8)",
      className: "toastify-success",
      duration: 5000,
      gravity: "bottom",
      text: "Message Sent",
    }).showToast();

  const handleSubmit = async event => {
    event.preventDefault();
    await connectSchema.validate(form);
    const data = {
      ...form,
      "g-recaptcha-response": token,
    };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(data),
      url: "/",
    };
    try {
      await axios(options);
      goodSend();
      setForm({ ...initialFormState });
    } catch (e) {
      if (!e.name) {
        e => badSend(e.message);
      } else {
        badInput(e.errors);
      }
    }
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center justify-content-center py-5 vh-100">
        <h1 className="title--author">Connect</h1>
        <form
          className="container"
          autoComplete="off"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          data-netlify-recaptcha="true"
          name="connect"
          onSubmit={handleSubmit}
        >
          <div className="d-flex flex-column align-items-center">
            <input type="hidden" name="connect" value="contact" />
            <input
              onChange={e => handleChange(e)}
              type="hidden"
              name="bot-field"
              value={form["bot-field"]}
            />
            <Input
              icon="fas fa-user-circle"
              name="username"
              onChange={e => handleChange(e)}
              // type="hidden"
              placeholder="Andrew Milas"
              type="text"
              value={form.username}
            />
            <Input
              icon="fas fa-envelope"
              name="email"
              onChange={e => handleChange(e)}
              placeholder="andy@geronimo-bar.co.th"
              type="email"
              value={form.email}
            />
            <Input
              icon="fas fa-globe"
              name="url"
              onChange={e => handleChange(e)}
              placeholder="https://geronimo-bar.co.th"
              type="url"
              value={form.url}
            />
            <Input
              icon="fas fa-comment-alt"
              name="subject"
              onChange={e => handleChange(e)}
              placeholder="Subject"
              type="text"
              value={form.subject}
            />
            <TextArea
              className="mt-2"
              style={{ width: "100%" }}
              rows="5"
              cols="50"
              name="message"
              onChange={e => handleChange(e)}
              placeholder="Your Message..."
              value={form.message}
            />
            <button
              className="btn btn-block btn-outline-light my-3"
              disabled={executing}
            >
              SEND
            </button>
            <Reaptcha
              badge="inline"
              className="my-3"
              data-netlify-recaptcha="true"
              theme="dark"
              onExpire={onExpire}
              onLoad={onLoad}
              onVerify={onVerify}
              ref={rcRef}
              sitekey="6LdXRWEUAAAAABtMhuLgDfFiL0aFgDL400t6N3YP"
              size="invisible"
            />
          </div>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Connect;
