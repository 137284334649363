import { object, string } from "yup";

export const connectSchema = object().shape({
  username: string().required("Name is required"),
  email: string()
    .trim()
    .required("Email is required")
    .email("Please fix your email address"),
  url: string()
    .trim()
    .url("Please fix your web address"),
  subject: string().required("Subject is required"),
  message: string().required("Message is required"),
});

export const ssSignUpSchema = object().shape({
  email: string()
    .trim()
    .required("Email is required")
    .email("Please fix your email address"),
  username: string().required("Name is required"),
});
