import React from "react";
import BackGroundImageSectionFP from "../components/bgFalsePositives";
import BackGroundImageSectionSS from "../components/bgSoulSurfer";
import Reviews from "../components/fp-reviews";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SoulSurferSignup from "../components/ss-signup";
import Header from "../components/header";
import { Element, Link } from "react-scroll";
import GetFalsePositives from "../components/getFalsePositives";
import Connect from "../components/connect";
import Footer from "../components/footer";

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={["Kim Aleksander", "Soul Surfer", "False Positives"]}
    />
    <BackGroundImageSectionSS>
      <h1 className="title--author">Kim Aleksander</h1>
      <Link
        activeClass="active"
        to="ss-signup"
        spy={true}
        smooth={true}
        duration={1000}
      >
        <button className="d-block d-lg-none btn btn-outline-light m-3">
          SOUL SURFER
        </button>
        <button className="d-none d-lg-block btn-lg btn btn-outline-light m-3">
          SOUL SURFER
        </button>
      </Link>
    </BackGroundImageSectionSS>
    <Element name="fp-reviews">
      <BackGroundImageSectionFP>
        <Reviews />
      </BackGroundImageSectionFP>
    </Element>
    <Element name="ss-signup">
      <BackGroundImageSectionSS>
        <SoulSurferSignup />
      </BackGroundImageSectionSS>
    </Element>
    <Element name="get-fp">
      <BackGroundImageSectionFP>
        <GetFalsePositives />
      </BackGroundImageSectionFP>
    </Element>
    <Element name="connect">
      <BackGroundImageSectionSS>
        <Connect />
      </BackGroundImageSectionSS>
    </Element>
  </Layout>
);

export default IndexPage;
