import React from "react";
import Reviews from "./fp-reviews-swiper";
import { Link } from "react-scroll";

const fpReviews = () => {
  return (
    <>
      <h1 className="title--fp">FALSE POSITIVES</h1>
      <h3 className="text-light text-center mb-4 fp-blurb">
        A Washington D.C. based techno-thriller with action taking place in
        Bangkok, Teheran, and Saigon.
      </h3>
      <Reviews />
      <Link
        activeClass="active"
        to="get-fp"
        spy={true}
        smooth={true}
        duration={1000}
      >
        <button className="d-block d-lg-none btn btn-outline-light m-3">
          GET FALSE POSITIVES
        </button>
        <button className="d-none d-lg-block btn-lg btn btn-outline-light m-3">
          GET FALSE POSITIVES
        </button>
      </Link>
    </>
  );
};

export default fpReviews;
