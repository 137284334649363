export const reviews = [
  {
    blurb:
      "\"From the sweltering streets of Thailand to the corridors of power in the U.S., the action builds to a frantic climax that leaves the reader gasping.\"",
    source: "~Russell Blake\n Author of THE GERONIMO BREACH",
  },
  {
    blurb:
      "\"A well-paced and imaginative page turner that will appeal not only to techno-junkies but to anyone who likes a well-spun yarn... Try it, you'll have fun.\"",
    source: "~The Kindle Book Review",
  },
  {
    blurb:
      "\"I can't say enough good things about this book. Once I started reading it, I literally couldn't put it down.\"",
    source: "~GoodReads Review",
  },
  {
    blurb:
      "\"Kim Alexander has created some memorable characters and pretty much thrown the book at them. The story races along to a thrilling and satisfying climax.\"",
    source: "~Amazon Reviewer",
  },
  {
    blurb:
      "\"Kim Aleksander's False Positives was a page turner. I found it absolutely captivating...\"",
    source: "~The TBR Pile",
  },
  {
    blurb:
      "\"Well thought out plot line and interesting characters. Highly recommended. Waiting for the next one.\"",
    source: "~Amazon Reviwer",
  },
];
