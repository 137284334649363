import React from "react";
import "../utils/fonts/fa-solid";
import "../utils/fonts/fontawesome";

const GetFalsePositives = () => {
  return (
    <div className="d-flex flex-column justify-content-center py-5 py-sm-0 mvh-100">
      <h1 className="title--fp mb-3 mt-4 mt-sm-5">FALSE POSITIVES</h1>
      <div className="d-flex flex-column flex-sm-row mb-3">
        <div className="col-sm-7 px-0 px-sm-2">
          <p
            className="text-light p-4 text-justify"
            style={{
              fontFamily: "Sanchez, serif",
              backgroundColor: "rgba(0,0,0,0.6)",
              borderRadius: "0.75rem",
              hyphens: "manual",
            }}
          >
            In 1972, a gifted student at Berkeley writes the first computer
            virus. When it’s run on the university mainframe it simply vanishes.
            Thirty-five years later, a government computer system issues
            ostensibly baseless assassination orders, and its creator goes in
            search of the ghost in the machine. What she discovers is a legacy
            black-ops program from the Vietnam Era that is alive and killing
            today. As she fights to prevent her brainchild from becoming a
            weapon for government-sanctioned murder, the protagonist is pitted
            against adversaries hell-bent on wielding the machine with
            Machiavellian ruthlessness to achieve their political ambitions.
            Joined by an eclectic band of characters, she plots to bring down
            the system before it is used to start a war of biblical proportions,
            and in doing so, she becomes marked for termination by her own
            creation.
          </p>
        </div>
        <div className="col-sm-5">
          <p
            className="text-light text-center"
            style={{
              fontFamily: "'Exo 2', sans-serif",
              fontSize: "1.25rem",
              textShadow: "2px 2px black",
            }}
          >
            GET FALSE POSITIVES
          </p>
          <a
            className="btn btn-block btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.amazon.com/FALSE-POSITIVES-Kim-Aleksander-ebook/dp/B006QVA8MW"
          >
            AMAZON
          </a>
          <a
            className="btn btn-block btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.barnesandnoble.com/w/false-positives-kim-aleksander/1111014074"
          >
            BARNES & NOBLE
          </a>
          <a
            className="btn btn-block btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.goodreads.com/book/show/13495786-false-positives"
          >
            GOODREADS
          </a>
          <a
            className="btn btn-block btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            href="https://itunes.apple.com/us/book/false-positives/id516206471?mt=11"
          >
            iBOOKS
          </a>
          <a
            className="btn btn-block btn-outline-light"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.kobo.com/th/en/ebook/false-positives"
          >
            KOBO
          </a>
        </div>
      </div>
    </div>
  );
};
export default GetFalsePositives;
