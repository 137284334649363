import React from "react";
import Swiper from "react-id-swiper";
import { css } from "@emotion/core";
import { reviews } from "../data/fp-reviews";
import { Pagination } from "swiper/dist/js/swiper.esm";

const FPSwiper = () => {
  const params = {
    modules: [Pagination],
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
    speed: 600,
    breakpoints: {
      10000: {
        slidesPerView: 3,
        spaceBetween: 16,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 16,
      },
      576: {
        slidesPerView: 1,
        spaceBetween: 16,
      },
    },
  };
  const renderReviews = () =>
    reviews.map(review => (
      <div
        className="text-light p-1 m-2"
        key={reviews.indexOf(review)}
        style={{ fontFamily: "sanchez" }}
      >
        <p>{review.blurb}</p>
        <p>{review.source}</p>
      </div>
    ));
  return (
    <div
      className="container"
      css={css`
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 0.75rem;
      `}
    >
      <Swiper {...params}>{renderReviews()}</Swiper>
    </div>
  );
};

export default FPSwiper;
